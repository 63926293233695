<template>
  <div class="page-fix" :style="!isFetching ? `background-image: url(${getBackgroundImage})`:''" :class="{'bg-cover' : !isLessDimensionImg}">
    <CRow class="m-0">
      <CCol class="p-0" id="bg-img" style="max-width: 680px">
        <img
          v-if="!isFetching"
          class="position-fixed d-none"
          :class="isTFW ? 'istfw' : ''"
          :src="getBackgroundImage"
          @load="getImageSize"
          :style="{
            width: `${getScreenSize.width - 17}px`,
            height: `${getScreenSize.height}px`,
          }"
          @error="
            $event.target.src = '/img/new_home_bg.png';
            $event.target.style = '';
          "
          ref="campaign_bg_img"
          alt="bg"
        />
      </CCol>
      <CCol class="p-0">
        <div class="mt-5 col-md-6 ml-auto mr-5">
          <CCard class="border-0 bg-white">
            <CCardBody>
              <div class="mb-2">
                <div class="row">
                  <div class="col-md-3 d-flex justify-content-center">
                    <img
                      class="img-fluid"
                      :src="getLogoImage"
                      @error="$event.target.src = '/img/tflogo.png'"
                      alt="logo"
                    />
                  </div>
                  <div class="col-md-9">
                    <h4 class="text-center">
                      {{ getCampaignById.welcome_text }}
                    </h4>
                  </div>
                </div>
                <hr color="text-primary" size="10" />
              </div>
              <div v-if="!isRegCandidate">
                <ValidationObserver
                  ref="login_campaign"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(checkCandidateExists)">
                    <CRow>
                      <CCol>
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="email_label"
                            class="required col-lg-12 col-md-12"
                            >Enter your email ID</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="email"
                                :value="candidate_email.email"
                                @input="handleEmailInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <div class="text-center">
                      <router-link to="/">
                        <CButton
                          color="primary"
                          variant="outline"
                          class="mr-3 btn-width"
                          >Cancel</CButton
                        >
                      </router-link>
                      <CButton
                        class="bg-primary text-white btn-width"
                        @click="checkCandidateExists"
                        >Next</CButton
                      >
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <div v-if="isRegCandidate">
                <h5 class="text-center text-primary mb-3">
                  Already Registered
                </h5>
                <p class="font-weight-bold h4 mb-3">
                  Welcome Back
                  <span class="text-primary">{{ candidateName }}</span>
                </p>
                <CRow class="mb-3">
                  <label class="col-md-3 text-primary">Email ID</label>
                  <CCol md="9">
                    <TextInput
                      name="email"
                      :value="candidateInfo.email"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
                <div v-if="candidateInfo.is_campaign">
                  <p class="mb-2">
                    You are already registered with
                    <span class="font-weight-bold">{{
                      candidateInfo.org_name
                    }},</span
                    > press Continue to complete campaign registration or Cancel to exit.
                  </p>
                  <div class="my-2 mt-5 text-center">
                    <CButton variant="outline" color="primary" @click="navToLogin">Cancel</CButton>
                    <CButton color="primary ml-3" @click="showPreReg">Continue1234</CButton>
                  </div>
                </div>

                <!-- TFW campaign – candidate registered with another TF Customer -->
                <div v-else-if="getCampaignById.is_tfw_campaign">
                  <p class="mb-2">
                    Your details are already registered with
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name }}
                    </span>, a member of the TalentFind World network – 
                    the global healthcare careers hub 
                  </p>
                  <p>
                    Press Confirm to use your 
                    <b class="font-weight-bold">{{ candidateInfo.org_name }}</b>
                     profile to quickly register with TalentfindWorld and
                     this campaign or Cancel to exit.
                  </p>
                </div>
                <!-- Non-TFW campaign – candidate registered with another TF Customer -->
                <div v-else-if="!getCampaignById.is_tfw_campaign && candidateInfo?.organisation_id === 148">
                  <p class="mb-2">
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name }}
                    </span>, is a member of the TalentFind World network – the global
                    healthcare careers hub. You’re already registered and have a
                    TalentFind profile. 
                  </p>
                  <p>
                    Press Confirm to use this profile to quickly register with 
                    <b class="font-weight-bold">{{ candidateInfo.org_name }}</b>
                     and this campaign or Cancel to exit.
                  </p>
                </div>
                <!-- Non-TFW campaign – candidate registered with TFW -->
                <div v-else-if="!(getCampaignById.is_tfw_campaign && candidateInfo?.organisation_id === 148)">
                  <p class="mb-2">
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name }}
                    </span>, is a member of the TalentFind World network – the global
                    healthcare careers hub. You’re already registered and have a
                    TalentFind profile. 
                  </p>
                  <p>
                    Press Confirm to use this profile to quickly register with 
                    <b class="font-weight-bold">{{ candidateInfo.org_name }}</b>
                     and this campaign or Cancel to exit.
                  </p>
                </div>

                <div class="my-2 text-center" v-if="!candidateInfo.is_campaign">
                  <CButton variant="outline" color="primary" @click="navToLogin">Cancel</CButton>
                  <CButton color="primary ml-3" @click="showPreReg(true)">Confirm</CButton>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";
import { BASE_URL } from "@/service_urls";
import Vue from "vue";

import TextInput from "@/components/reusable/Fields/TextInput";

import { mapActions, mapGetters } from "vuex";

extend("email", { ...email, message: "Invalid email" });
extend("required", { ...required, message: "This field is required" });
import { LOGIN_URL } from "@/helpers/helper";
export default {
  name: "CampaignLogin",
  components: {
    TextInput,
  },
  data() {
    return {
      candidate_email: {},
      isFetching: false,
      candidateInfo: null,
      width: null,
      height : null
    };
  },
  computed: {
    ...mapGetters(["getCampaignById", "getCandidateData"]),
    getBackgroundImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_background_image_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=background_image&${new Date().getTime()}`;
    },
    getScreenSize() {
      return {
        width: screen.width,
        height: screen.height,
      };
    },
    getLogoImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_logo_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=logo&${new Date().getTime()}`;
    },
    isTFW() {
      return this.$route.params.org_name === "tfw";
    },
    candidateName() {
      return this.candidateInfo
        ? `${this.candidateInfo?.first_name} ${this.candidateInfo.sur_name}`
        : null;
    },
    isRegCandidate() {
      return this.candidateInfo?.candidate_uid || false;
    },
    isLessDimensionImg() {
      if(this.width && this.height && this.width <= 680 && this.height <= 752 ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      "fetchCampaignById",
      "getRegisteredHospitalsByEmail",
      "showToast",
      "checkCandidateExistInCampaign",
    ]),
    handleImageError(event) {
      event.target.src = "/img/new_home_bg.png";
      event.target.style.width = this.getScreenSize.width + "px";
      event.target.style.height = this.getScreenSize.height + "px";
    },
    handleEmailInput(name, value) {
      Vue.set(this.candidate_email, name, value);
    },
    showPreReg() {
      this.$emit("candidateData", this.candidateInfo);
    },
    navToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    async checkCandidateExists() {
      const isValid = await this.$refs.login_campaign.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory field!",
        });
        return;
      }
      if (!this.candidate_email["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.candidate_email["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        return false;
      }
      const payload = {
        email: this.candidate_email["email"],
        campaign_id: this.getCampaignById.campaign_id,
      };
      this.candidateInfo = null;
      this.checkCandidateExistInCampaign(payload).then((res) => {
        if (res) {
          const data = {
            is_pre_register: true,
            email: this.candidate_email.email,
            first_name: res.first_name,
            sur_name: res.sur_name,
            phone: res.phone,
            candidate_uid: res.candidate_uid,
            is_campaign: res.is_campaign,
            campaign_pre_reg_candidate_id: res.campaign_pre_reg_candidate_id,
            candidate_type_id: res.candidate_type_id,
            candidate_type: res?.candidate_type?.candidate_type,
            org_name: res?.org_name,
            organisation_id: res?.organisation?.organisation_id,
            register_now: false,
            completed: res?.completed,
          };
          this.candidateInfo = data;
          if (!this.isRegCandidate) this.$emit("candidateData", data);
        } else {
          const data = {
            is_pre_register: true,
            email: this.candidate_email.email,
            register_now: true,
          };
          this.$emit("candidateData", data);
        }
      });
    },
    getImageSize() {
                    const img = new Image();
                    img.src = this.$refs.campaign_bg_img?.src;
                    img.onload = () => {
                        this.width = img.width;
                        this.height = img.height;
                    };
                },
  },
  mounted() {
    const {
      params: { campaign_id: cid },
    } = this.$router.currentRoute;
    this.campaign_id = cid || "";
    this.candidateInfo = null;
    this.fetchCampaignById(this.campaign_id);
  },
};
</script>

<style lang="scss" scoped>
.bg {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1084px) {
  #bg-img {
    display: none;
  }
}
.container {
  .card-group {
    .card {
      max-width: 450px;
      @media (min-width: 1000px) {
        margin-top: 50px;
      }
    }
  }
}
.page-fix {
  margin-top: -22px;
  margin-right: -30px;
  margin-left: -30px;
  min-height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
}
.istfw {
  padding-top: 22px;
}
.btn-width {
  width: 120px;
}
.bg-cover {
  background-size: cover;
}
</style>
